import React from 'react';
import { createRoot } from 'react-dom/client'
import './assets/css/App.css';
import {
	Navigate,
	Route,
	createBrowserRouter,
	createRoutesFromElements,
	RouterProvider,
	useLocation
} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import ChallengeLayout from './layouts/challenge';
import PlayerLayout from './layouts/player';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { AuthProvider, useIsAuthenticated } from "react-auth-kit";

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container!);

// RequireAuth not working properly; this is a workaround
// https://github.com/react-auth-kit/react-auth-kit/issues/1023#issuecomment-1426873839
const PrivateRoute = ({ children }: any) => {
	const isAuthenticated = useIsAuthenticated();
	const location = useLocation();

	if (isAuthenticated()) {
		return children;
	}

	return <Navigate
		to={`/auth/login`}
		state={{ from: location }}
		replace
	/>;
};



const router = createBrowserRouter(
	createRoutesFromElements(
		<>
			<Route path={`*`} element={<Navigate to={`/admin/`} />} />
			<Route path={`/admin/*`} element={<PrivateRoute><AdminLayout /></PrivateRoute>} />
			<Route path={`/player/:uuid`} element={<PrivateRoute><PlayerLayout /></PrivateRoute>} />
			<Route path={`/auth/*`} element={<AuthLayout />} />
			<Route path={`/challenge/:uuid`} element={<ChallengeLayout />} />
		</>
	)
);

root.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<AuthProvider authType={`localstorage`} authName={`_auth`} >
				<RouterProvider router={router} />
			</AuthProvider>
		</React.StrictMode>
	</ChakraProvider>,
);
