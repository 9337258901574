import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuthHeader } from "react-auth-kit";
// Chakra imports

import { Box } from '@chakra-ui/react';

// Component imports
import Footer from '../../components/footer/FooterAuth'

// Env imports
import { environment } from '../../lib/environment';
import AsciinemaPlayer from "../../components/player/AsciinemaPlayer";

export default function Player() {

	document.documentElement.dir = 'ltr';
	const { uuid } = useParams();
	const authHeader = useAuthHeader();
	const [recording, setRecording] = useState(null);
  const effectRan = useRef(false);


	useEffect(() => {
    if (effectRan.current === false) {
      const getRecording = async () => {
        try {
          const response = await axios({
            method: "GET",
            url: `${environment.SERVER_URL}/tasks/${uuid}/recording`,
            headers: { Authorization: authHeader() },
            responseType: "blob",
          });
          setRecording(response.data);
        } catch (error) {
          console.log(error);
        }
      };
      getRecording();
      return () => {
        effectRan.current = true;
      };
    }
	}, [uuid, authHeader]);

		if (recording === null) {
			return (
        <Box p={{ base: '130px', md: '80px', xl: '80px' }}>
            Recording not available yet.
          <Box p={5}>
            <Footer />
          </Box>
        </Box >
			)
		} else {
	return (
		<Box p={{ base: '130px', md: '80px', xl: '80px' }}>

			{recording && (
				<AsciinemaPlayer
					src={URL.createObjectURL(recording)}
					rows='40'
					cols='150'
					fit='none'
					theme='monokai'
					idleTimeLimit={2}
				/>
			)}
			<Box p={5}>
				<Footer />
			</Box>
		</Box >

	);
    }
}
