import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

// Chakra imports

import {
  Grid,
  Box,
  GridItem,
  Center,
  Text,
  Textarea,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

// Component imports
import Card from "../../components/card/Card";
import Footer from "../../components/footer/FooterAuth";

// Env imports
import { environment } from "../../lib/environment";

export default function Challenge() {
  document.documentElement.dir = "ltr";
  const { uuid } = useParams();
  const effectRan = useRef(false);
  let [notes, setNotes] = useState("");
  const [challenge, setChallenge] = useState<Challenge | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showTaskFinishedOverlay, setShowTaskFinishedOverlay] = useState(false);

  const OverlayOne = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );
  const [overlay] = useState(<OverlayOne />);

  const handleNotesBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length > 0) {
      axios({
        method: "POST",
        url: `${environment.SERVER_URL}/public/tasks/${uuid}/savenote`,
        data: { note: event.target.value },
      });
    }
  };

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
  };

  const submitTask = () => {
    axios({
      method: "PUT",
      url: `${environment.SERVER_URL}/public/tasks/${uuid}/stop`,
    });
    onClose();
    setShowTaskFinishedOverlay(true);
  };

  interface Challenge {
    id: number;
    name: string;
    description: string;
    reviewer_notes: string;
    problem_statement: string;
  }

  interface Task {
    id: number;
    name: string;
    status: string;
    challenge_id: number;
    challenge_name: string;
    uuid: string;
  }

  useEffect(() => {
    if (effectRan.current === false) {
      axios({
        method: "PUT",
        url: `${environment.SERVER_URL}/public/tasks/${uuid}/start`,
      }).then((response) => {
        const challenge: Challenge = response.data.response.challenge;
        const task: Task = response.data.response.task;

        if (task.status !== "running") {
          setShowTaskFinishedOverlay(true);
        } else {
          setChallenge(challenge);
        }
      });
      return () => {
        effectRan.current = true;
      };
    }
  }, [uuid, setShowTaskFinishedOverlay]);

  const loadInstructions = (challenge: Challenge) => {
    if (!challenge) {
      return <Text p={2}>Loading ...</Text>;
    } else {
      return (
        <>
          <Text fontSize="22px" fontWeight="700" lineHeight="100%" p={2} mb={2}>
            {challenge.name}
          </Text>
          <Text
            whiteSpace="pre-line"
            p={2}
            maxHeight="calc(55vh)"
            overflow="auto"
            dangerouslySetInnerHTML={{ __html: challenge.problem_statement }}
          />
        </>
      );
    }
  };

  const TaskFinishedOverlay = () => {
    return (
      <Modal isCentered isOpen={showTaskFinishedOverlay} onClose={() => {}}>
        {overlay}
        <ModalContent>
          <ModalHeader>Task finished</ModalHeader>
          <ModalBody>
            <Text p={2} mb={3}>
              This task has already been submitted.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <Box p={{ base: "130px", md: "80px", xl: "80px" }}>
      {showTaskFinishedOverlay && TaskFinishedOverlay()}
      <Grid templateColumns="repeat(4, 1fr)" gap={5}>
        <GridItem colSpan={3} rowSpan={2}>
          <Center>
            <Card
              alignItems="center"
              flexDirection="column"
              w="calc(100vw)"
              h="calc(85vh)"
              p={5}
            >
              {!showTaskFinishedOverlay && (
                <iframe
                  id="inlineFrame"
                  title="Inline Task Frame"
                  width="100%"
                  height="100%"
                  src={`${environment.TASKS_URL}/${uuid}`}
                ></iframe>
              )}
            </Card>
          </Center>
        </GridItem>
        <GridItem>
          <Card w="calc(20vw)" h="calc(73vh)">
            <Tabs isFitted variant="enclosed" colorScheme="brand">
              <TabList mb="1em">
                <Tab>Instructions</Tab>
                <Tab>Notes</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>{loadInstructions(challenge)}</TabPanel>
                <TabPanel>
                  <Textarea
                    value={notes}
                    onBlur={handleNotesBlur}
                    onChange={handleNotesChange}
                    placeholder="Write notes here. They will be saved with your challenge."
                    size="xl"
                    h="calc(55vh)"
                    p={5}
                    border={0}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Card>
        </GridItem>
        <GridItem>
          <Card alignItems="center" flexDirection="column" w="calc(20vw)" p={5}>
            <Center>
              <Button variant="brand" onClick={onOpen}>
                Submit
              </Button>
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent top={250}>
                  <ModalHeader>Submit task?</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Text p={2}>
                      Once you submit, you will no longer be able to access this
                      page.
                    </Text>
                  </ModalBody>

                  <ModalFooter>
                    <Button m={2} variant="brand" onClick={submitTask}>
                      Submit
                    </Button>
                    <Button onClick={onClose}>Go back</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Center>
          </Card>
        </GridItem>
      </Grid>
      <Box p={5}>
        <Footer />
      </Box>
    </Box>
  );
}
