// Chakra imports
import { useState, useEffect, useRef } from "react";

// Custom components
import Card from "components/card/Card";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { HSeparator } from "components/separator/Separator";
import {
  MdOutlineRocket,
  MdOutlineEuroSymbol,
  MdDoneAll,
  MdStopCircle,
  MdVideocam,
  MdExpandMore,
  MdExpandLess,
} from "react-icons/md";
import axios from "axios";
import {
  Box,
  Flex,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useColorModeValue,
  useDisclosure,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  useClipboard,
  Link,
  Collapse,
} from "@chakra-ui/react";
import { environment } from "../../../lib/environment";
import { useForm, SubmitHandler } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useAuthHeader } from "react-auth-kit";

export default function MainDashboard() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const stopTaskColor = useColorModeValue("red.700", "white");
  const watchTaskRecordingColor = useColorModeValue("blackAlpha.700", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [tasks, setTasks] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [ongoingTasks, setOngoingTasks] = useState(0);
  const [totalTasks, setTotalTasks] = useState(0);
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const effectRan = useRef(false);

  const startTask = (challenge_id: number, challenge_name: string) => {
    axios({
      method: "POST",
      url: `${environment.SERVER_URL}/tasks`,
      headers: {
        "Content-type": "application/json",
        Authorization: authHeader(),
      },
      data: JSON.stringify({
        name: challenge_name,
        challenge_id: challenge_id,
      }),
    })
      .then((response) => {
        setTasks([response.data.response, ...tasks]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const TasksTable = () => {
    type TaskObj = {
      id: number;
      name: string;
      challenge_id: number;
      challenge_name: string;
      uuid: string;
      status: string;
    };

    const Row = (t: TaskObj) => {
      const { onCopy, value, setValue, hasCopied } = useClipboard("");
      const [task, setTask] = useState(t);
      useEffect(() => {
        setValue(`${environment.SITE_URL}/challenge/${task.uuid}`);
      }, [value, setValue, task.uuid]);

      const stopTask = () => {
        axios({
          method: "PUT",
          url: `${environment.SERVER_URL}/tasks/${task.uuid}/stop`,
          headers: {
            "Content-type": "application/json",
            Authorization: authHeader(),
          },
        })
          .then((response) => {
            setTask(response.data.response);
          })
          .catch((error) => {
            console.log(error);
          });
      };

      return (
        <Tr key={task.id}>
          <Td>{task.name}</Td>
          <Td>{task.challenge_name}</Td>
          <Td>
            <Flex alignItems="center" gap="3">
              <Link color="brand.600" onClick={onCopy}>
                {hasCopied ? "Copied!" : task.uuid}
              </Link>
            </Flex>
          </Td>
          <Td>{task.status}</Td>
          <Td>
            <Flex alignItems="center" gap="3">
              {task.status === "running" && (
                <IconButton
                  onClick={stopTask}
                  aria-label="Stop Task"
                  size="32px"
                  fontSize="32px"
                  color={stopTaskColor}
                  icon={<Icon as={MdStopCircle} />}
                />
              )}
              {(task.status === "finished" || task.status === "archived") && (
                <Link
                  href={`${environment.SITE_URL}/player/${task.uuid}`}
                  isExternal
                >
                  <IconButton
                    aria-label="Watch Recording"
                    size="34px"
                    fontSize="34px"
                    color={watchTaskRecordingColor}
                    icon={<Icon as={MdVideocam} />}
                  />
                </Link>
              )}
            </Flex>
          </Td>
        </Tr>
      );
    };

    if (tasks === null || tasks.length === 0) {
      return (
        <Card
          flexDirection="column"
          w="100%"
          px="0px"
          pl={5}
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          No tasks found.
        </Card>
      );
    } else {
      return (
        <Card
          flexDirection="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex
            px="25px"
            mb="8px"
            justifyContent="space-between"
            align="center"
          >
            <Text fontSize="22px" fontWeight="700" lineHeight="100%">
              Tasks
            </Text>
          </Flex>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Challenge</Th>
                <Th>URL</Th>
                <Th>Status</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>{tasks.map((t: TaskObj) => Row(t))}</Tbody>
          </Table>
        </Card>
      );
    }
  };

  type CreateTaskModalProps = {
    challenge_id: number;
  };
  const CreateTaskModal = ({ challenge_id }: CreateTaskModalProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm<IFormInputs>();

    interface IFormInputs {
      challenge_id: number;
      task_name: string;
    }

    const onSubmitStartTask: SubmitHandler<IFormInputs> = (data) => {
      startTask(data.challenge_id, data.task_name);
      onClose();
      reset();
    };

    return (
      <>
        <Button variant="brand" onClick={onOpen}>
          Start
        </Button>
        <Modal id={`modal_${challenge_id}`} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent top={250}>
            <form
              id={`form_${challenge_id}`}
              onSubmit={handleSubmit(onSubmitStartTask)}
            >
              <ModalHeader>Start new task</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormLabel>Task name</FormLabel>
                <Input
                  type="hidden"
                  value={challenge_id}
                  {...register("challenge_id", { required: true })}
                />
                <Input
                  placeholder="Task name"
                  required={true}
                  {...register("task_name", { required: true })}
                  autoFocus
                />
                <ErrorMessage errors={errors} name="task_name" />
              </ModalBody>

              <ModalFooter>
                <Button variant="brand" type="submit">
                  Start
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    );
  };

  const challengesTable = () => {
    type ChallengeObj = {
      id: number;
      name: string;
      description: string;
      reviewer_notes: string;
      problem_statement: string;
    };

    const ProblemStatementModal = ({ statement }: { statement: string }) => {
      const { isOpen, onOpen, onClose } = useDisclosure();
      return (
        <>
          <Link color="brand.600" onClick={onOpen}>
            Read problem statement
          </Link>{" "}
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Problem Statement</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text
                  whiteSpace="pre-line"
                  p={2}
                  dangerouslySetInnerHTML={{ __html: statement }}
                />
              </ModalBody>

              <ModalFooter>
                <Button variant="brand" mr={3} onClick={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      );
    };

    const Row = (challenge: ChallengeObj) => {
      const { isOpen, onToggle } = useDisclosure();
      const collapsibleIcon = isOpen ? MdExpandLess : MdExpandMore;

      return (
        <Tr key={challenge.id}>
          <Td>
            <Text p={2}>{challenge.name}</Text>
          </Td>
          <Td>
            <Flex>
              <Text p={2}>{challenge.description}</Text>
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                onClick={onToggle}
                icon={
                  <Icon
                    w="28px"
                    h="28px"
                    as={collapsibleIcon}
                    color={brandColor}
                  />
                }
              />
            </Flex>
            <Collapse in={isOpen} animateOpacity>
              <HSeparator />
              <Text
                whiteSpace="pre-line"
                p={2}
                dangerouslySetInnerHTML={{ __html: challenge.reviewer_notes }}
              />
              <ProblemStatementModal statement={challenge.problem_statement} />
            </Collapse>
          </Td>
          <Td>
            <CreateTaskModal challenge_id={challenge.id} />
          </Td>
        </Tr>
      );
    };

    if (challenges === null || challenges.length === 0) {
      return (
        <Card
          flexDirection="column"
          w="100%"
          px="0px"
          pl={5}
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          No challenges found.
        </Card>
      );
    } else {
      return (
        <Card
          flexDirection="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex
            px="25px"
            mb="8px"
            justifyContent="space-between"
            align="center"
          >
            <Text fontSize="22px" fontWeight="700" lineHeight="100%">
              Challenges
            </Text>
          </Flex>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th width="150px">Name</Th>
                <Th>Description</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {challenges.map((challenge) => (
                <Row key={challenge.id} {...challenge} />
              ))}
            </Tbody>
          </Table>
        </Card>
      );
    }
  };

  useEffect(() => {
    if (effectRan.current === false) {
      axios({
        method: "GET",
        url: `${environment.SERVER_URL}/tasks`,
        headers: { Authorization: authHeaderValue },
      })
        .then((response) => {
          setTasks(response.data.response);
        })
        .catch((error) => {
          console.log(error);
        });
      axios({
        method: "GET",
        url: `${environment.SERVER_URL}/challenges`,
        headers: { Authorization: authHeaderValue },
      })
        .then((response) => {
          setChallenges(response.data.response);
        })
        .catch((error) => {
          console.log(error);
        });
      axios({
        method: "GET",
        url: `${environment.SERVER_URL}/tasks/stats`,
        headers: { Authorization: authHeaderValue },
      })
        .then((response) => {
          setOngoingTasks(response.data.response.running);
          setTotalTasks(response.data.response.total);
        })
        .catch((error) => {
          console.log(error);
        });

      return () => {
        effectRan.current = true;
      };
    }
  }, [authHeaderValue]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 3, lg: 3, "2xl": 3 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="28px" h="28px" as={MdOutlineEuroSymbol} color={brandColor} />}
            />
          }
          name="Your balance"
          value="0 €"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="28px" h="28px" as={MdOutlineRocket} color={brandColor} />}
            />
          }
          name="Ongoing tasks"
          value={ongoingTasks}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdDoneAll} color={brandColor} />
              }
            />
          }
          name="Total Tasks"
          value={totalTasks}
        />
      </SimpleGrid>
      <SimpleGrid columns={1} gap="20px" mb="20px">
        {challengesTable()}
        <TasksTable />
      </SimpleGrid>
    </Box>
  );
}
