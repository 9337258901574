let isProd = process.env.NODE_ENV === 'production'
export const environment = {
  PRODUCTION: isProd,
  SERVER_URL: isProd ? 'https://api.bringops.com' : 'http://localhost:8082',
  TASKS_URL: isProd ? 'https://task.bringops.com' : 'http://localhost',
  SITE_URL: isProd ? 'https://app.bringops.com' : 'http://localhost:3000'
//   SENTRY_DSN:
//     process.env.NX_SENTRY_DSN ||
//     '',
//   GOOGLE_CLIENT_ID: process.env.NX_GOOGLE_CLIENT_ID,
};
