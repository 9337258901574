import React from "react";
import { useGoogleLogin } from '@react-oauth/google';
import { useSignIn } from 'react-auth-kit';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { environment } from '../../../lib/environment';
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import DefaultAuth from "layouts/auth/Default";

// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const signIn = useSignIn();

  const login = useGoogleLogin({
    onSuccess: codeResponse => {
      console.log(codeResponse)

      axios({
        method: "POST",
        url: `${environment.SERVER_URL}/auth/google`,
        headers: { "Content-type": "application/json" },
        data: JSON.stringify({
          code: codeResponse.code,
        }),
      }).then((response) => {

        type TokenData = {
          id: number;
          exp: number
        };

        if (!response.data.error) {
          var decodedToken = jwtDecode<TokenData>(response.data.response);
          if (
            signIn({
              token: response.data.response,
              tokenType: "Bearer",
              authState: { id: decodedToken.id },
              expiresIn: Math.floor((new Date(decodedToken.exp * 1000).valueOf() - new Date().valueOf()) / 1000 / 60),
            })
          ) {
          } else {
            console.log("An error occurred. Try again");
          }
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    flow: 'auth-code',
  });

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Log In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Use your Google account to Log In!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <Button
            onClick={() => login()}
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Log in with Google
          </Button>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet? Click above to create one with Google.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;