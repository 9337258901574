import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// Chakra imports
import { Box, useColorModeValue } from '@chakra-ui/react';

// Layout components
import { SidebarContext } from 'contexts/SidebarContext';

import SignInCentered from 'views/auth/signIn';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useIsAuthenticated } from "react-auth-kit";

// Custom Chakra theme
export default function Auth() {
	const [toggleSidebar, setToggleSidebar] = useState(false);
	const isAuthenticated = useIsAuthenticated();

	const getRoute = () => {
		return window.location.pathname !== '/auth/full-screen-maps';
	};

	const authBg = useColorModeValue('white', 'navy.900');
	document.documentElement.dir = 'ltr';

	if (isAuthenticated()) {
		// If authenticated user, then redirect to dashboard

		return <Navigate to={`/`} />;
	} else {

		return (
			<Box>
				<SidebarContext.Provider
					value={{
						toggleSidebar,
						setToggleSidebar
					}}>
					<Box
						bg={authBg}
						float='right'
						minHeight='100vh'
						height='100%'
						position='relative'
						w='100%'
						transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
						transitionDuration='.2s, .2s, .35s'
						transitionProperty='top, bottom, width'
						transitionTimingFunction='linear, linear, ease'>
						{getRoute() ? (
							<Box mx='auto' minH='100vh'>
								<GoogleOAuthProvider clientId="48118132551-mgq01gh3okr28no852fk7mak9msfcrf3.apps.googleusercontent.com">
									<Routes>
										<Route path={`login`} element={<SignInCentered />} />
									</Routes>
								</GoogleOAuthProvider>
							</Box>
						) : null}
					</Box>
				</SidebarContext.Provider>
			</Box>
		);
	}
}
